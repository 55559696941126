// Types
import { Twitter } from 'next-seo/lib/types';

type BuildTwitterObjectInput = {
	cardType?: string | null;
	handle?: string | null;
	site?: string | null;
};

const buildTwitterObject = (input: BuildTwitterObjectInput): Twitter => {
	const { cardType, handle, site } = input;

	const twitterObject = { cardType: cardType || undefined, handle: handle || undefined, site: site || undefined };

	return twitterObject;
};

export default buildTwitterObject;
