import { CollectionPage, ListItem, WebPage, WithContext } from 'schema-dts';

import SchemaOrg from '@/components/SchemaOrg';
import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { resize } from '@/utils/image/resize';
import { BoardData } from 'src/pages/[[...board]]';

import { getImageAltText, getImageCopyright, getImageUrl } from '../../image';

import { defaultImage, publisher } from './constants';

function getStructuredDataItems(board: BoardData['board'], appUrl: string) {
	const nodes = board?.boardSections?.nodes || [];

	const flattenedNodes = nodes
		.filter(notNullOrUndefined)
		.map((node) => node.boardSectionItems.nodes)
		.flat(1);

	const normalizedBoardArticles = flattenedNodes
		.filter(notNullOrUndefined)
		.map((node) => node.articleByArticleId)
		.filter((node, index, self) => self.findIndex((innerNode) => innerNode?.rowId === node?.rowId) === index)
		.filter(notNullOrUndefined)
		.map((article) => ({ path: `${appUrl}${article.path}`, title: article.seoTitle }));

	const normalizedBoardBoards = flattenedNodes
		.filter(notNullOrUndefined)
		.map((node) => node.boardByBoardId)
		.filter((node, index, self) => self.findIndex((innerNode) => innerNode?.rowId === node?.rowId) === index)
		.filter(notNullOrUndefined)
		.map((board) => ({ path: `${appUrl}${board.slug},${board.rowId}`, title: board.seoTitle }));

	const normalizedItems = normalizedBoardArticles.concat(normalizedBoardBoards);

	return normalizedItems;
}

function buildHomepageStructuredData(board: BoardData['board'], appUrl: string, selfUrl: string) {
	if (!board) {
		return null;
	}

	const title = board.seoTitle || board.title;

	const schema: WithContext<WebPage> = {
		'@context': 'https://schema.org',
		'@type': 'WebPage',
		name: title,
		headline: title,
		url: appUrl,
		mainEntityOfPage: appUrl,
		publisher: publisher(selfUrl),
		image: defaultImage(selfUrl),
		potentialAction: {
			'@type': 'SearchAction',
			target: `${appUrl}/suche?param={search_term_string}`,
			query: 'required name=search_term_string',
		},
	};

	const normalizedItems = getStructuredDataItems(board, appUrl);

	const mappedItems: WithContext<ListItem>[] = normalizedItems.filter(notNullOrUndefined).map(
		(items, id): WithContext<ListItem> => ({
			'@context': 'https://schema.org',
			'@type': 'ListItem',
			name: items.title ?? undefined,
			url: items.path,
			position: id + 1,
		})
	);

	const boardStructuredSchema: WithContext<CollectionPage> = {
		'@context': 'https://schema.org',
		'@type': 'CollectionPage',
		headline: title,
		description: board.description,
		'@id': board.shareUrl,
		mainEntity: {
			'@type': 'ItemList',
			itemListElement: mappedItems,
			numberOfItems: normalizedItems?.length,
		},
		copyrightYear: new Date().getFullYear(),
	};

	return (
		<>
			<SchemaOrg schema={schema} />
			<SchemaOrg schema={boardStructuredSchema} />
		</>
	);
}

export function buildBoardStructuredData(board: BoardData['board'], appUrl: string, selfUrl: string) {
	if (!board) {
		return null;
	}

	const title = board.seoTitle || board.title;

	if (board.slug === '/') {
		return buildHomepageStructuredData(board, appUrl, selfUrl);
	}

	const teaserImage = getImageUrl(board.image) || defaultImage(selfUrl).url?.toString();
	const teaserImageResized = teaserImage ? resize(teaserImage, 'landscape', 'xxl') : undefined;
	const teaserImageAltText = getImageAltText({ image: board.image, seoTitle: board.seoTitle, title: board.title }) || '';
	const teaserImageCopyright = getImageCopyright(board.image) || '';

	const schema: WithContext<WebPage> = {
		'@context': 'https://schema.org',
		'@type': 'WebPage',
		description: board.description,
		headline: title,
		image: {
			'@type': 'ImageObject',
			caption: teaserImageAltText,
			height: { '@type': 'QuantitativeValue', value: 900, unitCode: 'px' },
			width: { '@type': 'QuantitativeValue', value: 1600, unitCode: 'px' },
			url: teaserImageResized,
			copyrightHolder: {
				'@type': 'Person',
				name: teaserImageCopyright,
			},
		},
		mainEntityOfPage: board.shareUrl,
		name: title,
		publisher: publisher(selfUrl),
		url: board.shareUrl,
	};

	const normalizedItems = getStructuredDataItems(board, appUrl);

	const mappedItems: WithContext<ListItem>[] = normalizedItems.filter(notNullOrUndefined).map(
		(items, id): WithContext<ListItem> => ({
			'@context': 'https://schema.org',
			'@type': 'ListItem',
			name: items.title ?? undefined,
			url: items.path,
			position: id + 1,
		})
	);

	const boardStructuredSchema: WithContext<CollectionPage> = {
		'@context': 'https://schema.org',
		'@type': 'CollectionPage',
		headline: title,
		description: board.description,
		'@id': board.shareUrl,
		mainEntity: {
			'@type': 'ItemList',
			itemListElement: mappedItems,
			numberOfItems: normalizedItems?.length,
		},
		copyrightYear: new Date().getFullYear(),
	};

	return (
		<>
			<SchemaOrg schema={schema} />
			<SchemaOrg schema={boardStructuredSchema} />
		</>
	);
}
