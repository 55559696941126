import { Link } from '@/components/Link/Link';
import { IconSystem } from '@/icons/IconSystem';

import styles from './SectionTitle.module.scss';

type Props = {
	hideTitle?: boolean;
	path: string | null;
	title: string | null;
	visitable?: boolean | null;
	className?: string;
};

const BoardSectionTitle = ({ hideTitle, path, title, visitable = true, className = '' }: Props) => {
	if (!title || hideTitle) {
		return null;
	}

	if (!path || !visitable) {
		return (
			<header>
				<h3 className={`heading2 ${className} ${styles.wrapper}`}>
					<span className={styles.title}>{title}</span>
				</h3>
			</header>
		);
	}

	return (
		<header>
			<h3 className={`heading2 ${className} ${styles.wrapper}`}>
				<Link className={styles.titleLink} href={path}>
					<IconSystem icon="next" variant="filled" className={styles.styledChevron} />

					{title}
				</Link>
			</h3>
		</header>
	);
};

export default BoardSectionTitle;
