import { NAVIGATION_ITEM_AUTHOR } from '@/config/constants';

type BuildAuthorProfileLinkInput = {
	isActive?: boolean | null;
	path?: string | null;
} | null;

// At the moment, we only generate links to activeAuthors. This might change.
const buildAuthorProfileLink = (author?: BuildAuthorProfileLinkInput) => {
	if (!author || !author.isActive || !author.path) {
		return null;
	}

	return `${NAVIGATION_ITEM_AUTHOR.path}${author.path}`;
};

export default buildAuthorProfileLink;
