import ArticleItemFeedItem from '@/components/Article/Items/ArticleItemFeedItem';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import { BoardSectionItemShortnewsItemFragment } from './__generated__/BoardSectionItemShortnewsItem.generated';

type Props = {
	shortnewsItem: BoardSectionItemShortnewsItemFragment;
};

const BoardSectionItemShortnewsItem = ({ shortnewsItem }: Props) => {
	const {
		constants: { appUrl },
	} = useEnvironment();
	const path = `/meldung${shortnewsItem.slug},${shortnewsItem.omId}`;
	const truncatedTitle = shortnewsItem.title || null;

	return (
		<Tracktor
			key={truncatedTitle || `${shortnewsItem.omId}`}
			trackingData={{
				data: {
					target: {
						title: shortnewsItem.title,
						url: `${appUrl}${path}`,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => <ArticleItemFeedItem onClick={() => trackEvent({})} path={path} title={truncatedTitle} />}
		</Tracktor>
	);
};

export default BoardSectionItemShortnewsItem;
