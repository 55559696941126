import { memo } from 'react';

import { ThemeImage } from '@/components/base/ThemeImage';

import ErrorImageDark from '../../Error/error-dark.svg';
import ErrorImageLight from '../../Error/error-light.svg';

import styles from './BoardSectionError.module.scss';

const BoardSectionError = memo(() => {
	return (
		<div className={styles.image}>
			<ThemeImage srcLight={ErrorImageLight} srcDark={ErrorImageDark} width="300" height="300" alt="" />
			<h2 className={styles.title}>Etwas ist schief gelaufen. Artikel momentan nicht verfügbar.</h2>
		</div>
	);
});

export default BoardSectionError;
