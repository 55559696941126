import ArticleItemBody from '@/components/Article/Items/ArticleItemBody';
import ArticleItemImage from '@/components/Article/Items/ArticleItemImage';
import { ArticleItemModuleTypes } from '@/components/Article/Items/ArticleItemModuleTypes';
import { ArticleItemPublicationDate } from '@/components/Article/Items/ArticleItemPublicationDate';
import ArticleItemTeaserText from '@/components/Article/Items/ArticleItemTeaserText';
import { ArticleItemTitleLink } from '@/components/Article/Items/ArticleItemTitleLink';
import BoardModuleMedia from '@/components/Board/Modules/BoardModuleMedia';
import { useCommentsCount } from '@/components/CommentsCount/use-comments-count';
import { ImageModule } from '@/components/Image/ImageModule';
import { Link } from '@/components/Link/Link';
import { BreakpointMedia } from '@/cutils/breakpoints/media';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { COMMENT_BUTTON_ID } from '@/cutils/hooks/useComments';
import { useDefaults } from '@/cutils/hooks/useDefaults';
import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import styles from './BoardSectionItemArticle.module.scss';
import BoardSectionItemAuthor from './BoardSectionItemAuthor';
import { BoardSectionItemArticleFragment } from './__generated__/BoardSectionItemArticle.generated';

type Props = {
	boardSectionItemArticle: BoardSectionItemArticleFragment;
	isLarge?: boolean;
	isHero?: boolean;
	isPriority?: boolean;
	className?: string;
	sizes: string;
};

const BoardSectionItemArticle = ({ boardSectionItemArticle, isLarge, isHero, isPriority, className = '', sizes }: Props) => {
	const { fallbackTeaserModule } = useDefaults();

	const {
		flags: { IS_COMMENTS_HIDDEN },
		constants: { appUrl },
	} = useEnvironment();

	const commentCount = useCommentsCount(boardSectionItemArticle.rowId);

	const [teaserModule = fallbackTeaserModule] = boardSectionItemArticle.teaserModule.nodes.filter(notNullOrUndefined);
	const modulesTypes = boardSectionItemArticle.modulesTypes.filter(notNullOrUndefined);

	const [mainAuthor] = boardSectionItemArticle.authors.nodes
		.filter(notNullOrUndefined)
		.map((authorNode) => authorNode.authorByAuthorGuid)
		.filter(notNullOrUndefined)
		.slice(0, 1);

	const [mappedLink] = (boardSectionItemArticle.links || [])
		.filter(notNullOrUndefined)
		.slice(0, 1)
		.map((link, index) => {
			const { url, label, meta } = link;
			let title = label;
			if (meta && meta.title) {
				title = meta.title;
			}

			return (
				<Link key={title || label || url || index} className={`body3 ${styles.articleLink}`} href={url ?? '#'}>
					<span className="body5">Mehr zum Thema:</span> {title || label}
				</Link>
			);
		});

	const ImageComp = boardSectionItemArticle.image ? (
		<ImageModule image={boardSectionItemArticle.image} isPriority={isPriority} enableZoomEffect={true} sizes={sizes} />
	) : null;

	const showComments = boardSectionItemArticle.allowComments && !IS_COMMENTS_HIDDEN && commentCount !== undefined && commentCount !== 0;

	const authorComponent = (
		<>
			<address>
				<BoardSectionItemAuthor author={mainAuthor} />
			</address>

			{mainAuthor && showComments ? <span className={styles.dot}>&bull;</span> : null}
			{showComments && (
				<>
					<Link
						className={`body6 ${styles.commentsCount} ${styles.commentsLink}`}
						href={{ pathname: boardSectionItemArticle.path, hash: COMMENT_BUTTON_ID }}
					>
						{commentCount} {commentCount <= 1 ? 'Kommentar' : 'Kommentare'}
					</Link>
				</>
			)}
		</>
	);

	let mediasection: JSX.Element | null = null;

	if (isHero && teaserModule && teaserModule?.type !== 'IMAGE' && isLarge) {
		// zyklischer Type Generation!!!
		mediasection = (
			<BoardModuleMedia
				teaserModule={teaserModule}
				articlePath={boardSectionItemArticle.path}
				isPriority={isPriority}
				BoardPublicationDate={boardSectionItemArticle.publicationDate}
				sizes={sizes}
			/>
		);
	} else {
		mediasection = <ArticleItemImage image={ImageComp} path={boardSectionItemArticle.path} title={boardSectionItemArticle.title} />;
	}

	return (
		<Tracktor
			trackingData={{
				data: {
					target: {
						title: boardSectionItemArticle.title,
						url: `${appUrl}${boardSectionItemArticle.path}`,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => (
				<>
					<article className={`${styles.wrapper} ${isLarge ? styles.isLargeWrapper : ''} ${className}`} onClick={trackEvent}>
						{mediasection}

						<div className={styles.content}>
							<header className={styles.header}>
								<section className={styles.metaModules}>
									<ArticleItemPublicationDate className="body6" publicationDate={boardSectionItemArticle.publicationDate} />

									<ArticleItemModuleTypes modulesTypes={modulesTypes} />
								</section>

								<ArticleItemTitleLink className={styles.title} headingClassName="heading1" path={boardSectionItemArticle.path}>
									{boardSectionItemArticle.title}
								</ArticleItemTitleLink>
							</header>

							<BreakpointMedia greaterThanOrEqual="md">
								{(className, renderChildren) => {
									return (
										<ArticleItemBody className={`${className} ${styles.teaser}`}>
											{renderChildren ? <ArticleItemTeaserText>{boardSectionItemArticle.teaserText ?? ''}</ArticleItemTeaserText> : null}
										</ArticleItemBody>
									);
								}}
							</BreakpointMedia>

							<footer className={styles.footer}>
								<section className={`body6 ${styles.authorsComments}`}>{authorComponent}</section>

								{mappedLink && (
									<>
										{/* This is the line that is displayed between the article and the links. */}
										<hr className={styles.line} />
										<section>{mappedLink}</section>
									</>
								)}
							</footer>
						</div>
					</article>
				</>
			)}
		</Tracktor>
	);
};

export default BoardSectionItemArticle;
