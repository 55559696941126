import BoardSectionItem from '../BoardSectionItem';

import styles from './BoardSectionItemFeed.module.scss';
import { BoardSectionItemFeedFragment } from './__generated__/BoardSectionItemFeed.generated';

type Props = {
	boardSectionItems: BoardSectionItemFeedFragment[];
};

const BoardSectionItemFeed = ({ boardSectionItems }: Props) => {
	const mappedBoardSectionItems = boardSectionItems.map((boardSectionItem) => (
		<BoardSectionItem className={styles.item} boardSectionItem={boardSectionItem} isFeedItem={true} key={boardSectionItem.rowId} />
	));

	return (
		<section className={styles.wrapper}>
			<div className={styles.innerWrapper}>{mappedBoardSectionItems}</div>
		</section>
	);
};

export default BoardSectionItemFeed;
