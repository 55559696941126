import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { Tracktor } from 'react-tracktor';

import { IMAGE_SIZE_ONE_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import BoardSectionItemFeed from '../Items/BoardSectionItemFeed';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import { BoardSectionTileCategoryLeft2Fragment } from './__generated__/BoardSectionTileCategoryLeft2.generated';

type Props = {
	boardSectionItems: BoardSectionTileCategoryLeft2Fragment;
	isHighlightSection?: boolean;
};

const BoardSectionTileCategoryLeft2 = ({ boardSectionItems }: Props) => {
	const filteredBoardSectionItems = boardSectionItems.nodes.filter(notNullOrUndefined);

	// Get the last two items.
	const [boardSectionItemMiddle, boardSectionItemRight] = filteredBoardSectionItems.slice(-2);
	// Remove the last two items.
	const rest = filteredBoardSectionItems.slice(0, -2);

	if (!boardSectionItemRight || !boardSectionItemMiddle) {
		return null;
	}

	return (
		<BoardSectionTileSection>
			<BoardSectionTileRow>
				<Tracktor trackingData={{ data: { inSectionPlacement: 0 } }}>
					<BoardSectionItemFeed boardSectionItems={rest} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 1 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemMiddle} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 2 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemRight} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>
			</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileCategoryLeft2;
