import { RichText } from '@/components/base/RichtText/RichtText';

import styles from './BoardSectionItemRichText.module.scss';

type Props = {
	hasIntroductionText?: boolean | null;
	introductionHeadline?: string | null;
	introductionText?: string | null;
};
const BoardSectionItemRichText = ({ introductionHeadline, introductionText, hasIntroductionText }: Props) => {
	return hasIntroductionText ? (
		<div className={styles.textDiv}>
			<div className={styles.innerDiv}>
				<h2 className={styles.headline}>{introductionHeadline}</h2>
				<RichText className="body4" text={introductionText} />
			</div>
		</div>
	) : null;
};

export default BoardSectionItemRichText;
