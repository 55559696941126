import { memo } from 'react';

import { logger } from '@/utils/logging/logger';
import { BoardData } from 'src/pages/[[...board]]';

import BoardSectionItemRichText from './Items/BoardSectionItemRichText';
import { BoardSectionItemBr24In100SecondsFragment } from './Items/__generated__/BoardSectionItemBR24In100Seconds.generated';
import BoardSectionTileCategoryLeft1 from './Tiles/BoardSectionTileCategoryLeft1';
import BoardSectionTileCategoryLeft2 from './Tiles/BoardSectionTileCategoryLeft2';
import BoardSectionTileCategoryRight1 from './Tiles/BoardSectionTileCategoryRight1';
import BoardSectionTileCategoryRight2 from './Tiles/BoardSectionTileCategoryRight2';
import BoardSectionTileRaisedLeft2 from './Tiles/BoardSectionTileRaisedLeft2';
import BoardSectionTileRaisedRight2 from './Tiles/BoardSectionTileRaisedRight2';
import BoardSectionTileRaisedRight2Weather from './Tiles/BoardSectionTileRaisedRight2Weather';
import BoardSectionTileRegional4 from './Tiles/BoardSectionTileRegional4';
import BoardSectionTileShortnewsRight1 from './Tiles/BoardSectionTileShortnewsRight1';
import BoardSectionTileShortnewsRight2 from './Tiles/BoardSectionTileShortnewsRight2';
import BoardSectionTileSmall3 from './Tiles/BoardSectionTileSmall3';
import BoardSectionTileSmall3Highlight from './Tiles/BoardSectionTileSmall3Highlight';
import BoardSectionTileSubjectsNewsWeather from './Tiles/BoardSectionTileSubjectsNewsWeather';
import { parseBoardMetaField } from './Tiles/utils';
import { BoardSectionTileFragment } from './__generated__/BoardSectionTile.generated';

type Props = {
	boardSection: BoardSectionTileFragment;
	isHighlightSection: boolean;
	newsVideo: BoardSectionItemBr24In100SecondsFragment | null | undefined;
	rundschau: NonNullable<BoardData['board']>['rundschau'];
	shortnewsItems: BoardData['allShortnews'];
	title?: string | null;
	boardLink?: string | null;
	showHeroTitleForMobile?: boolean | null;
	intruductionTextPage?: {
		hasIntroductionText?: boolean | null;
		introductionHeadline?: string | null;
		introductionText?: string | null;
	} | null;
};

const BoardSectionTile = memo(
	({
		boardLink,
		boardSection,
		isHighlightSection,
		newsVideo,
		rundschau,
		shortnewsItems,
		showHeroTitleForMobile,
		title,
		intruductionTextPage,
	}: Props) => {
		const parsedMetaField = parseBoardMetaField(boardSection.meta);
		const selectedDistrict: string | undefined | null = parsedMetaField?.district;
		const hasIntroductionText = intruductionTextPage?.hasIntroductionText;
		const introductionText = intruductionTextPage?.introductionText;
		const introductionHeadline = intruductionTextPage?.introductionHeadline;

		switch (boardSection.schema) {
			case 'SMALL_3':
				return <BoardSectionTileSmall3 boardSectionItems={boardSection.boardSectionItems} />;

			case 'HIGHLIGHT_3':
				return <BoardSectionTileSmall3Highlight boardSectionItems={boardSection.boardSectionItems} />;

			case 'RAISED_LEFT_SIMPLE':
				return <BoardSectionTileRaisedLeft2 boardSectionItems={boardSection.boardSectionItems} isHighlightSection={isHighlightSection} />;

			case 'RAISED_RIGHT_SIMPLE':
				return <BoardSectionTileRaisedRight2 boardSectionItems={boardSection.boardSectionItems} isHighlightSection={isHighlightSection} />;

			case 'CATEGORY_LEFT_1_BIG':
				return <BoardSectionTileCategoryLeft1 boardSectionItems={boardSection.boardSectionItems} />;

			case 'CATEGORY_LEFT_2_SMALL':
				return <BoardSectionTileCategoryLeft2 boardSectionItems={boardSection.boardSectionItems} />;

			case 'CATEGORY_RIGHT_1_BIG':
				return <BoardSectionTileCategoryRight1 boardSectionItems={boardSection.boardSectionItems} />;

			case 'CATEGORY_RIGHT_2_SMALL':
				return <BoardSectionTileCategoryRight2 boardSectionItems={boardSection.boardSectionItems} />;

			case 'RAISED_RIGHT_SHORTNEWS':
				return <BoardSectionTileShortnewsRight1 boardSectionItems={boardSection.boardSectionItems} shortnewsItems={shortnewsItems} />;

			case 'RAISED_RIGHT_SHORTNEWS_2_SMALL':
				return <BoardSectionTileShortnewsRight2 boardSectionItems={boardSection.boardSectionItems} shortnewsItems={shortnewsItems} />;

			case 'REGIONAL_4_SIMPLE':
				return (
					<BoardSectionTileRegional4
						boardSectionItems={boardSection.boardSectionItems}
						newsVideo={newsVideo}
						selectedDistrict={selectedDistrict}
						rundschau={rundschau}
					/>
				);

			case 'RAISED_RIGHT_SIMPLE_WEATHER':
				return (
					<>
						<BoardSectionTileRaisedRight2Weather boardSectionItems={boardSection.boardSectionItems} />
						<BoardSectionItemRichText
							introductionHeadline={introductionHeadline}
							introductionText={introductionText}
							hasIntroductionText={hasIntroductionText}
						/>
					</>
				);

			case 'SUBJECTS_NEWS_WEATHER':
				return (
					<>
						<BoardSectionTileSubjectsNewsWeather
							boardSectionItems={boardSection.boardSectionItems}
							title={title}
							boardLink={boardLink}
							showHeroTitleForMobile={showHeroTitleForMobile}
						/>
						<BoardSectionItemRichText
							introductionHeadline={introductionHeadline}
							introductionText={introductionText}
							hasIntroductionText={hasIntroductionText}
						/>
					</>
				);

			default:
				logger.error(`Unknown 'BoardSectionSchema' used: '${boardSection.schema}'. RowId: '${boardSection.rowId}'`);

				return null;
		}
	}
);

export default BoardSectionTile;
