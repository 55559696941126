import { BoardSectionFragment } from './__generated__/BoardSection.generated';

export const normalizeBoardLink = (boardLink: BoardSectionFragment['boardByBoardLink']) => {
	if (!boardLink) {
		return null;
	}

	if (boardLink.slug === '/') {
		return boardLink.slug;
	}

	return `${boardLink.slug},${boardLink.rowId}`;
};

export const getVisitable = (boardLink: BoardSectionFragment['boardByBoardLink']) => {
	if (!boardLink) {
		return null;
	}

	return boardLink.visitable;
};

export const getBoardSectionTitle = (boardSection: BoardSectionFragment) => {
	return boardSection.title || boardSection?.categoryByCategoryId?.title || null;
};
