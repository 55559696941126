import { RadioWidget } from '@/components/RadioWidget';
import { Tracktor } from 'react-tracktor';

import { IMAGE_SIZE_ONE_THIRD, IMAGE_SIZE_TWO_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import styles from './BoardSectionTileRaisedRight2Weather.module.scss';
import { BoardSectionTileRaisedRight2WeatherFragment } from './__generated__/BoardSectionTileRaisedRight2Weather.generated';

type Props = {
	boardSectionItems: BoardSectionTileRaisedRight2WeatherFragment;
	isHighlightSection?: boolean;
};

const BoardSectionTileRaisedRight2Weather = ({ boardSectionItems }: Props) => {
	const [boardSectionItemLeft, boardSectionItemRight] = boardSectionItems.nodes;

	if (!boardSectionItemLeft || !boardSectionItemRight) {
		return null;
	}

	return (
		<BoardSectionTileSection>
			<BoardSectionTileRow>
				<Tracktor trackingData={{ data: { inSectionPlacement: 0 } }}>
					<BoardSectionItem
						boardSectionItem={boardSectionItemLeft}
						isLarge={true}
						isHero={true}
						isPriority={true}
						key={boardSectionItemLeft.rowId}
						sizes={IMAGE_SIZE_TWO_THIRD}
					/>
				</Tracktor>

				<div className={styles.rightRoot}>
					<Tracktor trackingData={{ data: { inSectionPlacement: 1 } }}>
						<BoardSectionItem
							boardSectionItem={boardSectionItemRight}
							isHero={true}
							isPriority={false}
							key={boardSectionItemRight.rowId}
							sizes={IMAGE_SIZE_ONE_THIRD}
						/>
					</Tracktor>

					<RadioWidget />
				</div>
			</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileRaisedRight2Weather;
