import BoardSectionItemHighlight from '@/components/Board/Section/Items/BoardSectionItemHighlight';
import { ImageModule } from '@/components/Image/ImageModule';
import { useDefaults } from '@/cutils/hooks/useDefaults';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import { BoardSectionItemTeaserHighlightFragment } from './__generated__/BoardSectionItemTeaserHighlight.generated';

type Props = {
	boardSectionItemTeaserHighlight: BoardSectionItemTeaserHighlightFragment;
	sizes: string;
};

const BoardSectionItemTeaserHighlight = ({ boardSectionItemTeaserHighlight, sizes }: Props) => {
	const { fallbackImageModule } = useDefaults();

	const path = boardSectionItemTeaserHighlight?.link?.url;

	if (!path) {
		return null;
	}

	const imageComp = (
		<ImageModule image={boardSectionItemTeaserHighlight.image || fallbackImageModule} enableZoomEffect={true} sizes={sizes} />
	);

	return (
		<Tracktor
			trackingData={{
				data: {
					target: {
						title: boardSectionItemTeaserHighlight.title,
						url: path,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => (
				<BoardSectionItemHighlight
					image={imageComp}
					onClick={() => trackEvent({})}
					path={path}
					title={boardSectionItemTeaserHighlight.title}
				/>
			)}
		</Tracktor>
	);
};

export default BoardSectionItemTeaserHighlight;
