import { ImageModule } from '@/components/Image/ImageModule';
import { MediaAudioVideo } from '@/components/Media/MediaAudioVideo';
import { MediaLivestream } from '@/components/Media/MediaLivestream';
import getDurationFromMediaModule from '@/cutils/media/getDurationFromMediaModule';
import { MediaControlButtonVariant } from '@/types/enums';
import { logger } from '@/utils/logging/logger';

import styles from '../../../../shared/styles/mediacontainer.module.scss';

import { BoardModuleMediaFragment } from './__generated__/BoardModuleMedia.generated';

type Props = {
	BoardPublicationDate?: string | null;
	teaserModule: BoardModuleMediaFragment;
	articlePath: string;
	isPriority?: boolean;
	sizes: string;
};

const BoardModuleMedia = ({ articlePath, BoardPublicationDate, teaserModule, isPriority, sizes }: Props) => {
	const thumbnail = teaserModule.thumbnail;

	if (!thumbnail) {
		logger.error(`'thumbnail' not found in '${teaserModule.rowId}'`, 'BoardModuleMediaContainer');

		return null;
	}

	switch (teaserModule.type) {
		case 'IMAGE':
			return <ImageModule image={thumbnail} isPriority={isPriority} sizes={sizes} />;

		case 'AUDIO':
			if (!teaserModule.audio || !teaserModule.audio.url) {
				logger.error(`module is of type 'audio', but no audio or url was found in '${teaserModule.rowId}'.`, 'BoardModuleMediaContainer');

				return null;
			}

			const audioDuration = getDurationFromMediaModule(teaserModule);

			return (
				<div className={`${styles.mediaRoot}`}>
					<MediaAudioVideo
						buttonVariant={MediaControlButtonVariant.Audio}
						copyright={teaserModule.copyright}
						duration={audioDuration}
						meta={teaserModule.meta}
						publicationDate={BoardPublicationDate}
						thumbnailAltText={thumbnail.altText}
						thumbnailCopyright={thumbnail.copyright}
						thumbnailLink={articlePath}
						thumbnailUrl={thumbnail.url}
						title={teaserModule.title || undefined}
						url={teaserModule.audio.url}
						isPriority={isPriority}
						enableZoomEffect={true}
						sizes={sizes}
						rowId={teaserModule.rowId}
					/>
				</div>
			);

		case 'LIVESTREAM':
			const livestreamStartDate = (teaserModule?.livestream?.programme?.boardModuleBroadcasts?.nodes?.[0]?.start || null) as string | null;
			const livestreamEndDate = (teaserModule?.livestream?.programme?.boardModuleBroadcasts?.nodes?.[0]?.end || null) as string | null;
			const livestreamStatus = teaserModule?.livestream?.status || null;
			const liveStreamHlsUrl = teaserModule?.livestream?.videoHlsUrl || null;
			const onDemandVideos = teaserModule.livestream?.programme?.videoFiles?.nodes || null;

			if (!livestreamStatus) {
				logger.error(`module is of type 'livestream', but no Status was found in '${teaserModule.rowId}'.`, 'BoardModuleMediaContainer');

				return null;
			}

			return (
				<div className={`${styles.mediaRoot}`}>
					<MediaLivestream
						copyright={teaserModule.copyright}
						liveStreamHlsUrl={liveStreamHlsUrl}
						livestreamStartDate={livestreamStartDate}
						livestreamEndDate={livestreamEndDate}
						livestreamStatus={livestreamStatus}
						thumbnailAltText={thumbnail.altText}
						thumbnailCopyright={thumbnail.copyright}
						thumbnailUrl={thumbnail.url}
						thumbnailLink={articlePath}
						title={teaserModule.title || undefined}
						isPriority={isPriority}
						mediaVars={teaserModule.trackingInfos?.mediaVars}
						moduleId={teaserModule.id}
						customVars={teaserModule.trackingInfos?.customVars}
						videos={onDemandVideos}
						publicationDate={BoardPublicationDate}
						enableZoomEffect={true}
						sizes={sizes}
					/>
				</div>
			);

		case 'VIDEO':
			if (!teaserModule.video || !teaserModule.video.url) {
				logger.error(`module is of type 'video', but no video or url was found in '${teaserModule.rowId}'.`, 'BoardModuleMediaContainer');

				return null;
			}

			const videoDuration = getDurationFromMediaModule(teaserModule);

			return (
				<div>
					<MediaAudioVideo
						buttonVariant={MediaControlButtonVariant.Video}
						copyright={teaserModule.copyright}
						duration={videoDuration}
						meta={teaserModule.meta}
						publicationDate={BoardPublicationDate}
						thumbnailAltText={thumbnail.altText}
						thumbnailCopyright={thumbnail.copyright}
						thumbnailUrl={thumbnail.url}
						thumbnailLink={articlePath}
						title={teaserModule.title || undefined}
						url={teaserModule.video.url}
						isPriority={isPriority}
						rowId={teaserModule.rowId}
						enableZoomEffect={true}
						sizes={sizes}
					/>
				</div>
			);

		default:
			return null;
	}
};

export default BoardModuleMedia;
