import { Children } from 'react';

import { ArticleItemTitleLink } from '@/components/Article/Items/ArticleItemTitleLink';
import BoardSectionItemLink from '@/components/Board/Section/SectionItemLink';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { BoardQuery } from '@/domains/resolver/__generated__/resolve-board.generated';

import styles from './BoardSectionItemShortnews.module.scss';
import BoardSectionItemShortnewsItem from './BoardSectionItemShortnewsItem';

type Props = {
	shortnewsCount: number;
	shortnewsItems: BoardQuery['allShortnews'];
};

const BoardSectionItemShortnews = ({ shortnewsCount, shortnewsItems }: Props) => {
	const environment = useEnvironment();
	const radioUrl = `${environment.endpoints.ENDPOINT_RADIO}/br24?autoplay`;

	if (!shortnewsItems) {
		return null;
	}

	const mappedShortnewsItems = shortnewsItems.slice(0, shortnewsCount).map((shortnewsItem, index) => {
		return <BoardSectionItemShortnewsItem key={shortnewsItem.title || `${shortnewsItem.omId}-${index}`} shortnewsItem={shortnewsItem} />;
	});

	return (
		<section className={styles.wrapper}>
			<header className={styles.header}>
				<ArticleItemTitleLink headingClassName="heading1" as="h2" path="/meldung">
					BR24 Meldungen
				</ArticleItemTitleLink>
			</header>

			<section className={styles.body}>
				{Children.toArray(mappedShortnewsItems).length > 0 ? mappedShortnewsItems : <h3>Derzeit sind keine Nachrichten verfügbar.</h3>}
			</section>

			<footer className={styles.footer}>
				<ul className={styles.list}>
					<BoardSectionItemLink href="/meldung">Mehr</BoardSectionItemLink>

					<BoardSectionItemLink href={radioUrl} target="_blank">
						BR24 hören
					</BoardSectionItemLink>
				</ul>
			</footer>
		</section>
	);
};

export default BoardSectionItemShortnews;
