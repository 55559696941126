import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { Tracktor } from 'react-tracktor';

import { IMAGE_SIZE_TWO_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import BoardSectionItemFeed from '../Items/BoardSectionItemFeed';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import { BoardSectionTileCategoryLeft1Fragment } from './__generated__/BoardSectionTileCategoryLeft1.generated';

type Props = {
	boardSectionItems: BoardSectionTileCategoryLeft1Fragment;
	isHighlightSection?: boolean;
};

const BoardSectionTileCategoryLeft1 = ({ boardSectionItems }: Props) => {
	const filteredBoardSectionItems = boardSectionItems.nodes.filter(notNullOrUndefined);

	// Get the last item.
	const [boardSectionItemRight] = filteredBoardSectionItems.slice(-1);
	// Get all but the last item.
	const rest = filteredBoardSectionItems.slice(0, -1);

	if (!boardSectionItemRight) {
		return null;
	}

	return (
		<BoardSectionTileSection>
			<BoardSectionTileRow>
				<Tracktor trackingData={{ data: { inSectionPlacement: 0 } }}>
					<BoardSectionItemFeed boardSectionItems={rest} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 1 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemRight} isLarge={true} sizes={IMAGE_SIZE_TWO_THIRD} />
				</Tracktor>
			</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileCategoryLeft1;
