import ArticleItemBody from '@/components/Article/Items/ArticleItemBody';
import ArticleItemImage from '@/components/Article/Items/ArticleItemImage';
import ArticleItemTeaserText from '@/components/Article/Items/ArticleItemTeaserText';
import { ArticleItemTitleLink } from '@/components/Article/Items/ArticleItemTitleLink';
import { ImageModule } from '@/components/Image/ImageModule';
import buildBoardPath from '@/cutils/builders/buildBoardPath';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { useDefaults } from '@/cutils/hooks/useDefaults';
import { IconSystem } from '@/icons/IconSystem';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import styles from './BoardSectionItemBoard.module.scss';
import { BoardSectionItemBoardFragment } from './__generated__/BoardSectionItemBoard.generated';

type Props = {
	boardSectionItemBoard: BoardSectionItemBoardFragment;
	isLarge?: boolean;
	sizes: string;
};

const BoardSectionItemBoard = ({ boardSectionItemBoard, isLarge, sizes }: Props) => {
	const {
		constants: { appUrl },
	} = useEnvironment();
	const { fallbackImageModule } = useDefaults();

	const path = buildBoardPath(boardSectionItemBoard.rowId, boardSectionItemBoard.slug, boardSectionItemBoard.path);

	const imageComp = <ImageModule image={boardSectionItemBoard.image || fallbackImageModule} enableZoomEffect={true} sizes={sizes} />;

	return (
		<Tracktor
			trackingData={{
				data: {
					target: {
						title: boardSectionItemBoard.title,
						url: `${appUrl}${path}`,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => (
				<article className={`${styles.wrapper} ${isLarge ? styles.isLargeWrapper : ''}`} onClick={() => trackEvent({})}>
					<header className={styles.header}>
						<ArticleItemImage image={imageComp} path={path} title={boardSectionItemBoard.title} />
					</header>

					<ArticleItemBody className={styles.body} withVerticalPaddingBottom={true}>
						<section className={styles.metaModules}>
							<IconSystem icon="distribution" variant="filled" />
						</section>

						<ArticleItemTitleLink className={styles.title} headingClassName="heading1" path={path}>
							{boardSectionItemBoard.title}
						</ArticleItemTitleLink>
						<ArticleItemTeaserText>{boardSectionItemBoard.description}</ArticleItemTeaserText>
					</ArticleItemBody>
				</article>
			)}
		</Tracktor>
	);
};

export default BoardSectionItemBoard;
