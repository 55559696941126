import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { BoardQuery } from '@/domains/resolver/__generated__/resolve-board.generated';
import { Tracktor } from 'react-tracktor';

import { IMAGE_SIZE_ONE_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import BoardSectionItemShortnews from '../Items/BoardSectionItemShortnews';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import { BoardSectionTileShortnewsRight2Fragment } from './__generated__/BoardSectionTileShortnewsRight2.generated';

type Props = {
	boardSectionItems: BoardSectionTileShortnewsRight2Fragment;
	shortnewsItems: BoardQuery['allShortnews'];
};

const BoardSectionTileShortnewsRight2 = ({ boardSectionItems, shortnewsItems }: Props) => {
	const [boardSectionItemLeft, boardSectionItemCenter] = boardSectionItems.nodes.filter(notNullOrUndefined);

	if (!boardSectionItemLeft || !boardSectionItemCenter) {
		return null;
	}

	return (
		<BoardSectionTileSection>
			<BoardSectionTileRow>
				<Tracktor trackingData={{ data: { inSectionPlacement: 0 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemLeft} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 1 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemCenter} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 2 } }}>
					<BoardSectionItemShortnews shortnewsCount={5} shortnewsItems={shortnewsItems} />
				</Tracktor>
			</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileShortnewsRight2;
