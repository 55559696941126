import BoardSectionItemHeroNewsteaser from '@/components/Board/Section/Items/BoardSectionItemHeroNewsteaser';
import { Link } from '@/components/Link/Link';
import { RadioWidget } from '@/components/RadioWidget';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { Tracktor } from 'react-tracktor';

import { IMAGE_SIZE_TWO_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import styles from './BoardSectionTileSubjectsNewsWeather.module.scss';
import { BoardSectionTileSubjectsNewsWeatherFragment } from './__generated__/BoardSectionTileSubjectsNewsWeather.generated';

type Item = NonNullable<BoardSectionTileSubjectsNewsWeatherFragment['nodes'][number]>;

type Props = {
	boardSectionItems: BoardSectionTileSubjectsNewsWeatherFragment;
	title?: string | null;
	boardLink?: string | null;
	showHeroTitleForMobile?: boolean | null;
};

function getItem({ articleByArticleId, boardByBoardId, boardsTeaserByBoardsTeaserId }: Item) {
	return articleByArticleId || boardByBoardId || boardsTeaserByBoardsTeaserId;
}

const BoardSectionTileSubjectsNewsWeather = ({ boardSectionItems, title, boardLink, showHeroTitleForMobile }: Props) => {
	const { constants } = useEnvironment();
	const [boardSectionItemTopLeft, boardSectionItemTopCenter, boardSectionItemBottomLeft, boardSectionItemBottomCenter] =
		boardSectionItems.nodes;

	if (!boardSectionItemBottomCenter || !boardSectionItemBottomLeft || !boardSectionItemTopCenter || !boardSectionItemTopLeft) {
		return null;
	}

	const item1 = getItem(boardSectionItemTopCenter);
	const item2 = getItem(boardSectionItemBottomLeft);
	const item3 = getItem(boardSectionItemBottomCenter);

	const threeNewsData: unknown[] = [item1, item2, item3];

	let headline;

	if (!boardLink) {
		headline = <>{title}</>;
	} else {
		headline = <Link href={`${constants.BASE_URL}${boardLink}`}>{title}</Link>;
	}

	return (
		<BoardSectionTileSection>
			{showHeroTitleForMobile && (
				<div className={`${styles.newsTeaserHeadline} ${showHeroTitleForMobile ? styles.showMobileTitle : ''}`} data-theme="dark">
					{headline}
				</div>
			)}
			<BoardSectionTileRow>
				<Tracktor trackingData={{ data: { inSectionPlacement: 0 } }}>
					<BoardSectionItem
						className={styles.hero}
						boardSectionItem={boardSectionItemTopLeft}
						isLarge={true}
						isHero={true}
						isPriority={true}
						sizes={IMAGE_SIZE_TWO_THIRD}
					/>
				</Tracktor>

				<div className={styles.rightRoot}>
					<BoardSectionItemHeroNewsteaser
						threeNewsData={threeNewsData}
						title={title}
						boardLink={boardLink}
						showHeroTitleForMobile={showHeroTitleForMobile}
						isPriority={false}
					/>
					<RadioWidget className={styles.radioWidget} />
				</div>
			</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileSubjectsNewsWeather;
