import { ReactNode } from 'react';

import ArticleItemBody from '@/components/Article/Items/ArticleItemBody';
import ArticleItemImage from '@/components/Article/Items/ArticleItemImage';
import { ArticleItemTitleLink } from '@/components/Article/Items/ArticleItemTitleLink';

import styles from './BoardSectionItemHighlight.module.scss';

type Props = {
	image?: ReactNode;
	onClick?: () => void;
	path: string;
	title: string;
	className?: string;
};

const BoardSectionItemHighlight = ({ image, onClick, path, title, className = '' }: Props) => {
	return (
		<article className={`${styles.wrapper} ${className}`} onClick={onClick}>
			<header className={styles.header}>
				<ArticleItemImage image={image} path={path} title={title} />
			</header>

			<ArticleItemBody className={styles.body} withVerticalPaddingBottom={true}>
				<ArticleItemTitleLink className={styles.title} isHighlightSection={true} path={path}>
					{title}
				</ArticleItemTitleLink>
			</ArticleItemBody>
		</article>
	);
};

export default BoardSectionItemHighlight;
