import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { Tracktor } from 'react-tracktor';

import { IMAGE_SIZE_ONE_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import { BoardSectionTileSmall3Fragment } from './__generated__/BoardSectionTileSmall3.generated';

type Props = {
	boardSectionItems: BoardSectionTileSmall3Fragment;
};

const BoardSectionTileSmall3 = ({ boardSectionItems }: Props) => {
	const mappedBoardSectionItems = boardSectionItems.nodes.filter(notNullOrUndefined).map((boardSectionItem, index) => {
		return (
			<Tracktor key={boardSectionItem.rowId} trackingData={{ data: { inSectionPlacement: index } }}>
				<BoardSectionItem boardSectionItem={boardSectionItem} sizes={IMAGE_SIZE_ONE_THIRD} />
			</Tracktor>
		);
	});

	return (
		<BoardSectionTileSection>
			<BoardSectionTileRow>{mappedBoardSectionItems}</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileSmall3;
