const FAKTENFUCHS_BOARD_ROW_IDS = ['QzSIzl3'];

export const FaktenFuchsBadge: React.FC<{ boardRowId?: string }> = ({ boardRowId }) => {
	/**
	 * only output for board with rowId QzSIzl3 (Faktenfuchs)
	 */
	return !boardRowId || !FAKTENFUCHS_BOARD_ROW_IDS.includes(boardRowId) ? null : (
		<div style={{ visibility: 'hidden', height: 0, width: 0 }}>
			<a
				href="https://ifcncodeofprinciples.poynter.org/profile/bayerischer-rundfunk-br24-faktenfuchs"
				title="IFCN signatory"
				target="_blank"
				rel="noreferrer"
			>
				{/* eslint-disable-next-line @next/next/no-img-element */}
				<img
					src="https://cdn.ifcncodeofprinciples.poynter.org/storage/badges/5269DF66-80BC-BBD9-0061-EB1B2B6DFAFF.png"
					style={{ maxWidth: '100%' }}
					alt="IFCN signatory"
				/>
			</a>
		</div>
	);
};
