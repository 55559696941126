import { Tracktor } from 'react-tracktor';

import { IMAGE_SIZE_ONE_THIRD, IMAGE_SIZE_TWO_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import { BoardSectionTileRaisedLeft2Fragment } from './__generated__/BoardSectionTileRaisedLeft2.generated';

type Props = {
	boardSectionItems: BoardSectionTileRaisedLeft2Fragment;
	isHighlightSection?: boolean;
};

const BoardSectionTileRaisedLeft2 = ({ boardSectionItems }: Props) => {
	const [boardSectionItemLeft, boardSectionItemRight] = boardSectionItems.nodes;

	if (!boardSectionItemLeft || !boardSectionItemRight) {
		return null;
	}

	return (
		<BoardSectionTileSection>
			<BoardSectionTileRow>
				<Tracktor trackingData={{ data: { inSectionPlacement: 0 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemLeft} key={boardSectionItemLeft.rowId} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 1 } }}>
					<BoardSectionItem
						boardSectionItem={boardSectionItemRight}
						isLarge={true}
						key={boardSectionItemRight.rowId}
						sizes={IMAGE_SIZE_TWO_THIRD}
					/>
				</Tracktor>
			</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileRaisedLeft2;
