import { ReactNode } from 'react';

import styles from './TileSection.module.scss';

type Props = {
	children: ReactNode;
	isHighlightSection?: boolean;
};

const BoardSectionTileSection = ({ children, isHighlightSection }: Props) => {
	return <div className={`${styles.section} ${isHighlightSection ? styles.isHighlightSection : ''}`}>{children}</div>;
};

export default BoardSectionTileSection;
