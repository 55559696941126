import { NextSeoProps } from 'next-seo';

import { resize } from '@/utils/image/resize';

import { getImageAltText, getImageUrl } from '../image';
import { notNullOrUndefined } from '../misc/notNullOrUndefined';

import { BuildBoardSeoConfigFragment } from './__generated__/buildBoardSeoConfig.generated';
import buildOpenGraphObject from './buildOpenGraphObject';
import buildTwitterObject from './buildTwitterObject';
import { defaultImage } from './constants';

function getBoardTwitterHandle(board: BuildBoardSeoConfigFragment) {
	const socialMediaAccounts = board?.socialMediaAccounts || [];
	const twitterIndex = socialMediaAccounts
		.filter(notNullOrUndefined)
		.findIndex((socialMediaAccount) => socialMediaAccount.service === 'TWITTER');
	const twitterAccount = board?.socialMediaAccounts?.[twitterIndex]?.accountName;

	const handle = twitterAccount ? `@${twitterAccount} ` : undefined;

	return handle;
}

export function buildBoardSeoConfig(board: BuildBoardSeoConfigFragment, selfOrigin: string): NextSeoProps {
	const handle = getBoardTwitterHandle(board);
	const teaserImage = getImageUrl(board.image) || defaultImage(selfOrigin).url?.toString();
	const teaserImageAltText = getImageAltText({ image: board.image, seoTitle: board.seoTitle, title: board.title }) || '';
	const teaserImageResized = teaserImage ? resize(teaserImage, 'landscape', 'xxl') : '';

	const openGraph = buildOpenGraphObject({
		description: board.description,
		image: !!teaserImage ? teaserImageResized : undefined,
		imageAlt: !!teaserImage ? teaserImageAltText : undefined,
		imageHeight: !!teaserImage ? 900 : undefined,
		imageWidth: !!teaserImage ? 1600 : undefined,
		seoTitle: board.seoTitle,
		title: board.title,
		url: board.shareUrl,
	});

	// Twitter uses the `og:` properties for the rest.
	const twitter = buildTwitterObject({ handle });

	// Misc
	const canonical = board.shareUrl || undefined;

	return { canonical, description: board.description || undefined, openGraph, title: board.seoTitle || undefined, twitter };
}
