import styles from './ArticleItemFeedItem.module.scss';
import { ArticleItemPublicationDate } from './ArticleItemPublicationDate';
import { ArticleItemTitleLink } from './ArticleItemTitleLink';

type Props = {
	onClick?: () => void;
	path: string;
	publicationDate?: string | null;
	title: string | null;
	className?: string;
};

const ArticleItemFeedItem = ({ onClick, path, publicationDate, title, className = '' }: Props) => {
	if (!title) {
		return null;
	}

	return (
		<article className={`${styles.wrapper} ${className}`} onClick={onClick}>
			<header className={`${styles.header} ${!publicationDate ? styles.withVerticalPadding : ''}`}>
				{publicationDate && (
					<section className={styles.meta}>
						<ArticleItemPublicationDate className="body6" publicationDate={publicationDate} />
					</section>
				)}

				<ArticleItemTitleLink as="h3" className={styles.title} headingClassName="body1" path={path}>
					{title}
				</ArticleItemTitleLink>
			</header>
		</article>
	);
};

export default ArticleItemFeedItem;
