import ArticleItemFeedItem from '@/components/Article/Items/ArticleItemFeedItem';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import { BoardSectionItemFeedItemTeaserFragment } from './__generated__/BoardSectionItemFeedItemTeaser.generated';

type Props = {
	boardSectionItemTeaser: BoardSectionItemFeedItemTeaserFragment;
	className?: string;
};

const BoardSectionItemFeedItemTeaser = ({ boardSectionItemTeaser, className = '' }: Props) => {
	const {
		constants: { appUrl },
	} = useEnvironment();
	const path = boardSectionItemTeaser?.link?.url;

	if (!path) {
		return null;
	}

	return (
		<Tracktor
			trackingData={{
				data: {
					target: {
						title: boardSectionItemTeaser.title,
						url: `${appUrl}${path}`,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => (
				<ArticleItemFeedItem className={className} onClick={() => trackEvent({})} path={path} title={boardSectionItemTeaser.title} />
			)}
		</Tracktor>
	);
};

export default BoardSectionItemFeedItemTeaser;
