import { ReactNode } from 'react';

import styles from './TileRow.module.scss';

type Props = {
	children: ReactNode;
};

const BoardSectionTileRow = ({ children }: Props) => {
	return <div className={styles.row}>{children}</div>;
};

export default BoardSectionTileRow;
