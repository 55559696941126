import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { Tracktor } from 'react-tracktor';

import { IMAGE_SIZE_TWO_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import BoardSectionItemFeed from '../Items/BoardSectionItemFeed';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import { BoardSectionTileCategoryRight1Fragment } from './__generated__/BoardSectionTileCategoryRight1.generated';

type Props = {
	boardSectionItems: BoardSectionTileCategoryRight1Fragment;
	isHighlightSection?: boolean;
};

const BoardSectionTileCategoryRight1 = ({ boardSectionItems }: Props) => {
	const [boardSectionItemLeft, ...rest] = boardSectionItems.nodes.filter(notNullOrUndefined);

	if (!boardSectionItemLeft) {
		return null;
	}

	return (
		<BoardSectionTileSection>
			<BoardSectionTileRow>
				<Tracktor trackingData={{ data: { inSectionPlacement: 0 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemLeft} isLarge={true} sizes={IMAGE_SIZE_TWO_THIRD} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 1 } }}>
					<BoardSectionItemFeed boardSectionItems={rest} />
				</Tracktor>
			</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileCategoryRight1;
