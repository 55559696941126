import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { Tracktor } from 'react-tracktor';

import { IMAGE_SIZE_ONE_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import BoardSectionItemFeed from '../Items/BoardSectionItemFeed';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import { BoardSectionTileCategoryRight2Fragment } from './__generated__/BoardSectionTileCategoryRight2.generated';

type Props = {
	boardSectionItems: BoardSectionTileCategoryRight2Fragment;
	isHighlightSection?: boolean;
};

const BoardSectionTileCategoryRight2 = ({ boardSectionItems }: Props) => {
	const [boardSectionItemLeft, boardSectionItemMiddle, ...rest] = boardSectionItems.nodes.filter(notNullOrUndefined);

	if (!boardSectionItemLeft || !boardSectionItemMiddle) {
		return null;
	}

	return (
		<BoardSectionTileSection>
			<BoardSectionTileRow>
				<Tracktor trackingData={{ data: { inSectionPlacement: 0 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemLeft} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 1 } }}>
					<BoardSectionItem boardSectionItem={boardSectionItemMiddle} sizes={IMAGE_SIZE_ONE_THIRD} />
				</Tracktor>

				<Tracktor trackingData={{ data: { inSectionPlacement: 2 } }}>
					<BoardSectionItemFeed boardSectionItems={rest} />
				</Tracktor>
			</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileCategoryRight2;
