import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { Tracktor } from 'react-tracktor';

import { IMAGE_SIZE_ONE_THIRD } from '../../BoardImageSizes';
import BoardSectionItem from '../BoardSectionItem';
import BoardSectionTileRow from '../Tile/TileRow';
import BoardSectionTileSection from '../Tile/TileSection';

import { BoardSectionTileSmall3HighlightFragment } from './__generated__/BoardSectionTileSmall3Highlight.generated';

type Props = {
	boardSectionItems: BoardSectionTileSmall3HighlightFragment;
};

const BoardSectionTileSmall3Highlight = ({ boardSectionItems }: Props) => {
	const mappedBoardSectionItems = boardSectionItems.nodes.filter(notNullOrUndefined).map((boardSectionItem, index) => {
		return (
			<Tracktor key={boardSectionItem.rowId} trackingData={{ data: { inSectionPlacement: index } }}>
				<BoardSectionItem boardSectionItem={boardSectionItem} isHighlightSection={true} sizes={IMAGE_SIZE_ONE_THIRD} />
			</Tracktor>
		);
	});

	return (
		<BoardSectionTileSection isHighlightSection={true}>
			<BoardSectionTileRow>{mappedBoardSectionItems}</BoardSectionTileRow>
		</BoardSectionTileSection>
	);
};

export default BoardSectionTileSmall3Highlight;
