import { lg, xl } from '@/shared/styles/breakpoints';

/**
 * The sizes to be used for board items that occupy one third of the available space on desktop
 */
export const IMAGE_SIZE_ONE_THIRD = `(max-width: ${lg}px) 100vw, (max-width: ${xl}px) 33vw, ${xl / 3}px`;

/**
 * The sizes to be used for board items that occupy two third of the available space on desktop
 */
export const IMAGE_SIZE_TWO_THIRD = `(max-width: ${lg}px) 100vw, (max-width: ${xl}px) 66vw, ${(xl / 3) * 2}px`;
