import BoardSectionItemHighlight from '@/components/Board/Section/Items/BoardSectionItemHighlight';
import { ImageModule } from '@/components/Image/ImageModule';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { useDefaults } from '@/cutils/hooks/useDefaults';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import { BoardSectionItemArticleHighlightFragment } from './__generated__/BoardSectionItemArticleHighlight.generated';

type Props = {
	boardSectionItemArticleHighlight: BoardSectionItemArticleHighlightFragment;
	className?: string;
	sizes: string;
};

const BoardSectionItemArticleHighlight = ({ boardSectionItemArticleHighlight, className = '', sizes }: Props) => {
	const {
		constants: { appUrl },
	} = useEnvironment();
	const { fallbackImageModule } = useDefaults();
	const imageComp = (
		<ImageModule image={boardSectionItemArticleHighlight.image || fallbackImageModule} enableZoomEffect={true} sizes={sizes} />
	);

	return (
		<Tracktor
			trackingData={{
				data: {
					target: {
						title: boardSectionItemArticleHighlight.title,
						url: `${appUrl}${boardSectionItemArticleHighlight.path}`,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => (
				<BoardSectionItemHighlight
					className={className}
					image={imageComp}
					onClick={() => trackEvent({})}
					path={boardSectionItemArticleHighlight.path}
					title={boardSectionItemArticleHighlight.title}
				/>
			)}
		</Tracktor>
	);
};

export default BoardSectionItemArticleHighlight;
