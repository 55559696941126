import { ReactNode } from 'react';

import { LinkWithChevron } from '@/components/Link/LinkWithChevron';

type Props = {
	children: ReactNode;
	href: string;
	target?: '_blank';
};

const BoardSectionItemLink = ({ children, href, target }: Props) => {
	return (
		<li>
			<LinkWithChevron className="body3" href={href} target={target}>
				{children}
			</LinkWithChevron>
		</li>
	);
};

export default BoardSectionItemLink;
