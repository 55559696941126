import { parseISO } from 'date-fns';
import { format } from 'date-fns/format';
import { de } from 'date-fns/locale';

import { MediaAudioVideo } from '@/components/Media/MediaAudioVideo';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { MediaControlButtonVariant } from '@/types/enums';
import { logger } from '@/utils/logging/logger';

import { IMAGE_SIZE_ONE_THIRD } from '../../BoardImageSizes';

import styles from './BoardSectionItemBR24In100Seconds.module.scss';
import { BoardSectionItemBr24In100SecondsFragment } from './__generated__/BoardSectionItemBR24In100Seconds.generated';

type Props = {
	newsVideo: BoardSectionItemBr24In100SecondsFragment | null | undefined;
};

export function BoardSectionItemBR24In100Seconds({ newsVideo }: Props) {
	const { images } = useEnvironment();

	if (!newsVideo) {
		logger.error(`'newsVideo' not found for BoardSectionItemBR24In100Seconds`);

		return null;
	}

	const duration = !!newsVideo.duration ? Math.ceil(newsVideo.duration) : null;
	const publicationDate = newsVideo.updateDate;

	const formattedDate = publicationDate ? format(parseISO(publicationDate), "dd.MM.yyyy, HH:mm 'Uhr'", { locale: de }) : '';

	return (
		<section className={styles.wrapper}>
			<header className={styles.header}>
				<MediaAudioVideo
					buttonVariant={MediaControlButtonVariant.Video}
					duration={duration}
					publicationDate={publicationDate}
					thumbnailAltText={`BR24 100 Sekunden vom ${formattedDate}`}
					thumbnailUrl={newsVideo.thumbnail || images.DEFAULT_RUNDSCHAU_IMAGE}
					title={newsVideo.title}
					url={newsVideo.url}
					showTitle={true}
					customTitle="BR24 100 Sekunden"
					playButtonTitle={`BR24 100 Sekunden vom ${formattedDate} abspielen`}
					sizes={IMAGE_SIZE_ONE_THIRD}
					rowId={100100}
				/>
			</header>
		</section>
	);
}
