import { InferGetServerSidePropsType } from 'next';

import { Board } from '@/components/Board/Board';
import { NAVIGATION_ITEM_HOME } from '@/config/constants';
import { CacheTag } from '@/cutils/page-context/edge-cache-tags';
import { createDefaultGetServerSideProps } from '@/cutils/page-context/server-side-props';
import { envConfigServer } from '@/sconfig/env.server';
import { extractSlugAndIdFromPathSegment } from '@/utils/pathUtils';
import { BoardQuery } from 'src/domains/resolver/__generated__/resolve-board.generated';
import { resolveBoard } from 'src/domains/resolver/resolve-board';

type Props = InferGetServerSidePropsType<typeof getServerSideProps>;

function BoardPage({ data }: Props) {
	return <Board data={data} />;
}

/**
 * Returns the category title for a board by following rules:
 * 1. use the category title if a category is set for the board
 * 2. use the navigation title if the board is included in the navigation and a navigation title has been set
 * 3. use the title of the board
 *
 * @param boardData
 * @returns the title to be used for the board
 */
function getBoardCategoryTitle(boardData: BoardData['board']) {
	if (!boardData) {
		return '';
	}

	if (boardData.categoryByCategoryId?.title) {
		return boardData.categoryByCategoryId?.title;
	} else if (boardData.navigationsByBoard.nodes?.[0]?.text) {
		return boardData.navigationsByBoard.nodes?.[0]?.text;
	} else {
		return boardData.title;
	}
}

export type BoardData = Omit<BoardQuery, 'board'> & { board: NonNullable<NonNullable<NonNullable<BoardQuery['board']>['nodes']>[number]> };

export const getServerSideProps = createDefaultGetServerSideProps(async (ctx, apolloClient, withCacheTags) => {
	try {
		let id: string | undefined;
		let slug: string | undefined;

		// the catch all route will return an array https://nextjs.org/docs/pages/building-your-application/routing/dynamic-routes#optional-catch-all-segments
		if (!ctx.params?.board || ctx.params?.board?.length === 0) {
			id = envConfigServer.HOMEPAGE_BOARD_ROW_ID;
			slug = '';
		} else if (ctx.params?.board?.length === 1) {
			const parsedPath = extractSlugAndIdFromPathSegment(ctx.params.board[0]);
			id = parsedPath.id;
			slug = parsedPath.slug;
		} else {
			// if we have more than 1 path segment there is no match for this url
			return { notFound: true };
		}

		const resolutionResult = await resolveBoard(apolloClient, id, slug, withCacheTags, ctx.resolvedUrl, ctx.req.logger);

		if ('redirect' in resolutionResult || 'notFound' in resolutionResult) {
			return resolutionResult;
		}

		let cacheTags = new Set<CacheTag>();

		const navigationTitle =
			id === envConfigServer.HOMEPAGE_BOARD_ROW_ID ? NAVIGATION_ITEM_HOME.pageTitle : getBoardCategoryTitle(resolutionResult.board);
		if (withCacheTags) {
			cacheTags = new Set((resolutionResult.board.withCache ?? []) as CacheTag[]);
		}

		return { props: { data: resolutionResult, navigationTitle, cacheTags } };
	} catch (err: any) {
		ctx.req.logger.error(`Error resolving data for board | ${err.message}`, err);
		throw err;
	}
});

export default BoardPage;
