// @ts-ignore: autogenerated import
import type * as Types from '../../types/global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RadioWidgetQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RadioWidgetQuery = {
	__typename?: 'Query';
	viewer?: {
		__typename?: 'MangoViewer';
		broadcastService?: {
			__typename?: 'MangoBroadcastService';
			id: string;
			epg?: Array<{
				__typename?: 'MangoEPGEntry';
				broadcastEvent?: {
					__typename?: 'MangoBroadcastEvent';
					start?: any | null;
					end?: any | null;
					publicationOf?:
						| { __typename?: 'MangoBestOf'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoBoard'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoClip'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoCreativeWork'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoExtra'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoGrouping'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoImage'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoItem'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoMakingOf'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoPlaylist'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoProgramme'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoSeason'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoSeries'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoTrailer'; kicker?: string | null; title?: string | null }
						| { __typename?: 'MangoWebPage'; kicker?: string | null; title?: string | null }
						| null;
				} | null;
			} | null> | null;
		} | null;
	} | null;
};

export const RadioWidgetDocument = gql`
	query RadioWidget {
		viewer {
			broadcastService(id: "http://ard.de/ontologies/ard#B5_aktuell") {
				... on MangoBroadcastService {
					id
					epg(slots: CURRENT) {
						broadcastEvent {
							start
							end
							publicationOf {
								kicker
								title
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * __useRadioWidgetQuery__
 *
 * To run a query within a React component, call `useRadioWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useRadioWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRadioWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function useRadioWidgetQuery(baseOptions?: Apollo.QueryHookOptions<RadioWidgetQuery, RadioWidgetQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RadioWidgetQuery, RadioWidgetQueryVariables>(RadioWidgetDocument, options);
}
export function useRadioWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RadioWidgetQuery, RadioWidgetQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RadioWidgetQuery, RadioWidgetQueryVariables>(RadioWidgetDocument, options);
}
export function useRadioWidgetSuspenseQuery(
	baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RadioWidgetQuery, RadioWidgetQueryVariables>
) {
	const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<RadioWidgetQuery, RadioWidgetQueryVariables>(RadioWidgetDocument, options);
}
export type RadioWidgetQueryHookResult = ReturnType<typeof useRadioWidgetQuery>;
export type RadioWidgetLazyQueryHookResult = ReturnType<typeof useRadioWidgetLazyQuery>;
export type RadioWidgetSuspenseQueryHookResult = ReturnType<typeof useRadioWidgetSuspenseQuery>;
export type RadioWidgetQueryResult = Apollo.QueryResult<RadioWidgetQuery, RadioWidgetQueryVariables>;
