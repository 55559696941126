import { BoardData } from 'src/pages/[[...board]]';

/**
 * Checks if the board has a hero based on the provided board data.
 *
 * @param board The board data to be checked
 * @return  Whether the board has a hero or not
 */
export const hasHero = (board: BoardData['board']) =>
	!!board.boardSections.nodes[0]?.schema &&
	(board.boardSections.nodes[0].schema === 'RAISED_RIGHT_SIMPLE_WEATHER' ||
		board.boardSections.nodes[0].schema === 'SUBJECTS_NEWS_WEATHER');
