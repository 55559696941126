import ArticleItemFeedItem from '@/components/Article/Items/ArticleItemFeedItem';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import { BoardSectionItemFeedItemArticleFragment } from './__generated__/BoardSectionItemFeedItemArticle.generated';

type Props = {
	boardSectionItemArticle: BoardSectionItemFeedItemArticleFragment;
	className?: string;
};

const BoardSectionItemFeedItemArticle = ({ boardSectionItemArticle, className = '' }: Props) => {
	const {
		constants: { appUrl },
	} = useEnvironment();
	return (
		<Tracktor
			trackingData={{
				data: {
					target: {
						title: boardSectionItemArticle.title,
						url: `${appUrl}${boardSectionItemArticle.path}`,
					},
					type: TrackEventDataType.BoardClick,
				},
			}}
		>
			{({ trackEvent }) => (
				<ArticleItemFeedItem
					className={className}
					onClick={() => trackEvent({})}
					path={boardSectionItemArticle.path}
					publicationDate={boardSectionItemArticle.publicationDate}
					title={boardSectionItemArticle.title}
				/>
			)}
		</Tracktor>
	);
};

export default BoardSectionItemFeedItemArticle;
