import BoardSectionItemArticle from './Items/BoardSectionItemArticle';
import BoardSectionItemArticleHighlight from './Items/BoardSectionItemArticleHighlight';
import BoardSectionItemBoard from './Items/BoardSectionItemBoard';
import BoardSectionItemBoardHighlight from './Items/BoardSectionItemBoardHighlight';
import { BoardSectionItemEmbed } from './Items/BoardSectionItemEmbed';
import BoardSectionItemFeedItemArticle from './Items/BoardSectionItemFeedItemArticle';
import BoardSectionItemFeedItemBoard from './Items/BoardSectionItemFeedItemBoard';
import BoardSectionItemFeedItemTeaser from './Items/BoardSectionItemFeedItemTeaser';
import BoardSectionItemTeaser from './Items/BoardSectionItemTeaser';
import BoardSectionItemTeaserHighlight from './Items/BoardSectionItemTeaserHighlight';
import { BoardSectionItemFragment } from './__generated__/BoardSectionItem.generated';

/**
 * Feed items do not have images, therefore `sizes` is only required when `isFeedItem` is a falsy value.
 *
 * Feed items and highlight sections are exclusive.
 */
type FeedTypeProps =
	| {
			isFeedItem: true;
			isHighlightSection?: false;
	  }
	| {
			isFeedItem?: false;
			isHighlightSection?: true;
			sizes: string;
	  };

type BoardSectionItemType = FeedTypeProps;

type Props = {
	boardSectionItem: BoardSectionItemFragment;
	isLarge?: boolean;
	isHero?: boolean;
	isPriority?: boolean;
	className?: string;
} & BoardSectionItemType;

const BoardSectionItem = ({ boardSectionItem, isLarge, isHero, isPriority, className = '', ...rest }: Props) => {
	if (!!boardSectionItem.articleByArticleId) {
		if (rest.isHighlightSection) {
			return (
				<BoardSectionItemArticleHighlight
					className={className}
					boardSectionItemArticleHighlight={boardSectionItem.articleByArticleId}
					sizes={rest.sizes}
				/>
			);
		}

		if (rest.isFeedItem) {
			return <BoardSectionItemFeedItemArticle className={className} boardSectionItemArticle={boardSectionItem.articleByArticleId} />;
		}

		return (
			<BoardSectionItemArticle
				className={className}
				boardSectionItemArticle={boardSectionItem.articleByArticleId}
				isLarge={isLarge}
				isHero={isHero}
				isPriority={isPriority}
				sizes={rest.sizes}
			/>
		);
	}

	if (!!boardSectionItem.boardByBoardId) {
		if (rest.isHighlightSection) {
			return <BoardSectionItemBoardHighlight boardSectionItemBoardHighlight={boardSectionItem.boardByBoardId} sizes={rest.sizes} />;
		}

		if (rest.isFeedItem) {
			return <BoardSectionItemFeedItemBoard className={className} boardSectionItemBoard={boardSectionItem.boardByBoardId} />;
		}

		return <BoardSectionItemBoard boardSectionItemBoard={boardSectionItem.boardByBoardId} isLarge={isLarge} sizes={rest.sizes} />;
	}

	if (!!boardSectionItem.boardsTeaserByBoardsTeaserId) {
		if (rest.isHighlightSection) {
			return (
				<BoardSectionItemTeaserHighlight
					boardSectionItemTeaserHighlight={boardSectionItem.boardsTeaserByBoardsTeaserId}
					sizes={rest.sizes}
				/>
			);
		}

		if (rest.isFeedItem) {
			return (
				<BoardSectionItemFeedItemTeaser className={className} boardSectionItemTeaser={boardSectionItem.boardsTeaserByBoardsTeaserId} />
			);
		}

		return (
			<BoardSectionItemTeaser boardSectionItemTeaser={boardSectionItem.boardsTeaserByBoardsTeaserId} isLarge={isLarge} sizes={rest.sizes} />
		);
	}

	if (!!boardSectionItem.embedInstanceByEmbedId) {
		return (
			<BoardSectionItemEmbed
				boardSectionItemEmbed={boardSectionItem.embedInstanceByEmbedId}
				isLarge={isLarge}
				sectionItemId={boardSectionItem.rowId}
			/>
		);
	}

	return null;
};

export default BoardSectionItem;
